<script>
import LoadSections from '@/mixins/load-sections';
import View from '@/views/View';

export default {
  name: 'KDAStory',
  metaInfo: { title: 'Story' },
  extends: View,

  mixins: [
    LoadSections([
      'KDAStoryKeDA',
      'KDAStoryJourney',
      'KDAStoryBegin',
      'KDAStoryToTeam',
      'KDAAboutAskInterest',
      'KDABookBanner'
    ])
  ],

  props: {
    id: {
      type: String,
      default: 'story'
    }
  },

  mounted() {
    window.gtag('event', 'page_view', {
      'page_title': 'Story'
    })
  }, 
};
</script>
